import React from "react"

export default function Privacy() {
  return (
    <div className="mx-6">
      <h3 className="text-2xl font-medium font-heading my-3">Privacy Policy</h3>

      <div className="row features inner">
        Quiz Me Ai built this app for Commercial purpose. This SERVICE is
        provided by Quiz Me Ai and is intended for use as is. This page is used
        to inform website visitors regarding policies with the collection, use,
        and disclosure of Personal Information if anyone decided to use this
        Service. If you choose to use this Service, then you agree to the
        collection and use of information in relation to this policy. The
        Personal Information that we collect is used for providing and improving
        the Service. We will not use or share your information with anyone
        except as described in this Privacy Policy. The terms used in this
        Privacy Policy have the same meanings as in our Terms and Conditions,
        which is accessible at the app unless otherwise defined in this Privacy
        Policy.
        <h4 className="text-xl font-medium font-heading text-gray-600 my-3">
          Information Collection and Use
        </h4>
        For a better experience, while using our Service, we may require you to
        provide us with certain personally identifiable information. The
        information that I request is retained on your device and is not
        collected by me in any way The app does use third party services that
        may collect information used to identify you. We want to inform you that
        whenever you use this Service, in a case of an error in the app we
        collect data and information (through third party products). This Data
        may include information such as your device Internet Protocol (“IP”)
        address, device name, operating system version, the configuration of the
        app when utilizing this Service, the time and date of your use of the
        Service, and other statistics.
        <h4 className="text-xl font-medium font-heading text-gray-600 my-3">
          Cookies
        </h4>
        Cookies are files with small amount of data that is commonly used an
        anonymous unique identifier. These are sent to your browser from the
        website that you visit and are stored on your device internal memory.
        This Service uses cookies; (1) to store information of user sessions and
        (2) through third party vendors. However, the app may use third party
        code and libraries that use “cookies” to collection information and to
        improve their services. You have the option to either accept or refuse
        these cookies and know when a cookie is being sent to your device. If
        you choose to refuse our cookies, you may not be able to use some
        portions of this Service. Service Providers We may employ third-party
        companies and individuals due to the following reasons: To facilitate
        our Service; To provide the Service on our behalf; To perform
        Service-related services; or To assist us in analyzing how our Service
        is used. We want to inform users of this Service that these third
        parties have access to your Personal Information. The reason is to
        perform the tasks assigned to them on our behalf. However, they are
        obligated not to disclose or use the information for any other purpose.
        <h4 className="text-xl font-medium font-heading text-gray-600 my-3">
          Security
        </h4>
        We value your trust in providing us your Personal Information, thus we
        are striving to use commercially acceptable means of protecting it. But
        remember that no method of transmission over the internet, or method of
        electronic storage is 100% secure and reliable, and we cannot guarantee
        its absolute security.
        <h4 className="text-xl font-medium font-heading text-gray-600 my-3">
          Children’s Privacy
        </h4>
        These Services do not address anyone under the age of 13. We do not
        knowingly collect personally identifiable information from children
        under 13. In the case we discover that a child under 13 has provided me
        with personal information, we immediately delete this from our servers.
        If you are a parent or guardian and you are aware that your child has
        provided us with personal information, please contact us so that we will
        be able to do necessary actions.
        <h4 className="text-xl font-medium font-heading text-gray-600 my-3">
          Changes to This Privacy Policy
        </h4>
        We may update our Privacy Policy from time to time. Thus, you are
        advised to review this page periodically for any changes. We will notify
        you of any changes by posting the new Privacy Policy on this page. These
        changes are effective immediately after they are posted on this page.
        <h4 className="text-xl font-medium font-heading text-gray-600 my-3">
          Contact Us
        </h4>
        If you have any questions or suggestions about my Privacy Policy, do not
        hesitate to contact us at support@quizme.ai.
      </div>
    </div>
  )
}

import * as React from "react"

import Layout from "../components/layout"
import Privacy from "../components/privacy"
import Seo from "../components/seo"

const PrivacyPage = () => (
  <Layout>
    <Seo title="Privacy" />
    <Privacy />
  </Layout>
)

export default PrivacyPage
